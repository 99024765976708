import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'

export default function useExcel() {
  const exportAsExcelFile = (json, excelFileName) => {
    const worksheet = XLSX.utils.json_to_sheet(json)
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    saveAsExcelFile(excelBuffer, excelFileName)
  }

  const saveAsExcelFile = (buffer, fileName) => {
    const data = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(data, fileName + '_export_' + new Date().toISOString().slice(0, 10) + EXCEL_EXTENSION)
  }

  return {
    exportAsExcelFile,
  }
}
