<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <v-card-title class="d-flex">
        <div class="mr-auto">Enquiries</div>
        <div class="d-flex">
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            clearable
            class="text-search me-3 mb-4"
          >
          </v-text-field>
          <v-btn icon class="mb-4" @click="showFilters = !showFilters">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                  {{ icons.mdiFilter }}
                </v-icon>
              </template>
              <span>Filters</span>
            </v-tooltip>
          </v-btn>
          <v-btn icon class="mb-4" @click="exportToExcel()">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                  {{ icons.mdiDownload }}
                </v-icon>
              </template>
              <span>Download</span>
            </v-tooltip>
          </v-btn>
        </div>
      </v-card-title>

      <!-- actions -->
      <v-card-text class="d-flex flex-wrap pb-0" v-if="showFilters">
        <v-select
          v-model="typeFilter"
          placeholder="Select Type"
          :items="typeOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <v-select
          v-model="dateFilter"
          placeholder="Select Date"
          :items="dateOptions"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <v-dialog
          v-if="dateFilter == 'Custom'"
          ref="dialog1"
          v-model="dateModal1"
          :return-value.sync="dateRange1"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRange1"
              placeholder="Select Date"
              :prepend-inner-icon="icons.mdiCalendar"
              readonly
              outlined
              hide-details
              dense
              clearable
              v-bind="attrs"
              v-on="on"
              class="user-search me-3 mb-4"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateRange1" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateModal1 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.dialog1.save(dateRange1)"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="filteredEnquiriesListTable"
        :items-per-page="10"
        :options.sync="options"
        :loading="enquiriesListTable.length == 0"
        :footer-props="{ itemsPerPageOptions: [10, 50, 100, -1] }"
      >
       <template v-slot:item.enq_id="{ item }">
          <v-list-item class="px-0">
            <v-list-item-content>
              <span v-if="item.enq_id !== undefined">{{ item.enq_id }}</span>
              <span v-else>-</span
          >
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.srno="{ index, item }">
          <span>{{ index + 1 }}</span><br>
          <v-icon size="14">{{ icons[getPlatformIconName(item.mplatform)] }}</v-icon>
        </template>
        <template v-slot:item.fullName="{ item }">
          <v-list-item class="px-0">
            <v-list-item-content>
              <span>{{ item.fullName }}</span
          ><br />
          <small>{{ item.phone }}</small>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.etype="{ item }">
          <span>{{ capitalize(item.etype) }}</span>
        </template>
        <template v-slot:item.createdon="{ item }">
          <span>{{ formatDate(item.createdon.toDate(), 'DD-MMM-YYYY') }}</span
          ><br />
          <span>{{ formatDate(item.createdon.toDate(), 'hh:mm:ss A') }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import { mdiExportVariant, mdiFilter, mdiDownload, mdiCalendar, mdiAndroid, mdiAppleIos, mdiWeb } from '@mdi/js'
import useEnquiriesList from './useEnquiriesList'
import useMoment from '../../../../composables/useMoment'
import useExcel from '@/composables/useExcel'
import { capitalize } from '@core/utils/filter'

export default {
  components: {},
  setup() {
    const dateModal1 = ref(false)
    const showFilters = ref(true)

    const {
      enquiriesListTable,
      filteredEnquiriesListTable,
      tableColumns,
      searchQuery,
      loading,
      options,
      typeOptions,
      typeFilter,
      dateOptions,
      dateFilter,
      dateRange1,

      unsubscribeEnquiries,
    } = useEnquiriesList()

    const { formatDate } = useMoment()

    const { exportAsExcelFile } = useExcel()

    const exportToExcel = () => {
      const excelArray = filteredEnquiriesListTable.value.map(item => {
        return {
          Type: item.etype,
          'Full Name': item.fullName,
          Platform: item.mplatform,
          Phone: item.phone,
          Email: item.email,
          Comments: item.comments,
          Location: item.city,
        }
      })

      exportAsExcelFile(excelArray, 'Enquiry_list')
    }

    const getPlatformIconName = type => {
      if (type == 'Android') {
        return 'mdiAndroid'
      } else if (type == 'iOS') {
        return 'mdiAppleIos'
      }
      return 'mdiWeb'
    }

    onUnmounted(() => {
      unsubscribeEnquiries()
    })

    return {
      dateModal1,
      showFilters,
      enquiriesListTable,
      filteredEnquiriesListTable,
      tableColumns,
      searchQuery,
      loading,
      options,
      dateOptions,
      dateFilter,
      dateRange1,
      typeOptions,
      typeFilter,
      formatDate,
      exportToExcel,
      getPlatformIconName,
      capitalize,
      // icons
      icons: {
        mdiExportVariant,
        mdiFilter,
        mdiDownload,
        mdiCalendar,
        mdiAndroid,
        mdiAppleIos,
        mdiWeb,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.user-search {
  max-width: 200px;
}
.text-search {
  min-width: 300px;
}
</style>
