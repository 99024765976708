var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-auto"},[_vm._v("Enquiries")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"text-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-btn',{staticClass:"mb-4",attrs:{"icon":""},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-1",attrs:{"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiFilter)+" ")])]}}])},[_c('span',[_vm._v("Filters")])])],1),_c('v-btn',{staticClass:"mb-4",attrs:{"icon":""},on:{"click":function($event){return _vm.exportToExcel()}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-1",attrs:{"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])]}}])},[_c('span',[_vm._v("Download")])])],1)],1)]),(_vm.showFilters)?_c('v-card-text',{staticClass:"d-flex flex-wrap pb-0"},[_c('v-select',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Type","items":_vm.typeOptions,"item-text":"title","item-value":"value","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.typeFilter),callback:function ($$v) {_vm.typeFilter=$$v},expression:"typeFilter"}}),_c('v-select',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Date","items":_vm.dateOptions,"outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}}),(_vm.dateFilter == 'Custom')?_c('v-dialog',{ref:"dialog1",attrs:{"return-value":_vm.dateRange1,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.dateRange1=$event},"update:return-value":function($event){_vm.dateRange1=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Select Date","prepend-inner-icon":_vm.icons.mdiCalendar,"readonly":"","outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.dateRange1),callback:function ($$v) {_vm.dateRange1=$$v},expression:"dateRange1"}},'v-text-field',attrs,false),on))]}}],null,false,1747014446),model:{value:(_vm.dateModal1),callback:function ($$v) {_vm.dateModal1=$$v},expression:"dateModal1"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dateRange1),callback:function ($$v) {_vm.dateRange1=$$v},expression:"dateRange1"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateModal1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog1.save(_vm.dateRange1)}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.filteredEnquiriesListTable,"items-per-page":10,"options":_vm.options,"loading":_vm.enquiriesListTable.length == 0,"footer-props":{ itemsPerPageOptions: [10, 50, 100, -1] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.enq_id",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',[(item.enq_id !== undefined)?_c('span',[_vm._v(_vm._s(item.enq_id))]):_c('span',[_vm._v("-")])])],1)]}},{key:"item.srno",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(index + 1))]),_c('br'),_c('v-icon',{attrs:{"size":"14"}},[_vm._v(_vm._s(_vm.icons[_vm.getPlatformIconName(item.mplatform)]))])]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',[_c('span',[_vm._v(_vm._s(item.fullName))]),_c('br'),_c('small',[_vm._v(_vm._s(item.phone))])])],1)]}},{key:"item.etype",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.capitalize(item.etype)))])]}},{key:"item.createdon",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdon.toDate(), 'DD-MMM-YYYY')))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdon.toDate(), 'hh:mm:ss A')))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }